<template>
  <ul class="list-unstyled mt-4 mb-0">
    <li v-for="provider in enabledPaymentProviders"
      v-bind:key="provider.name"
      class="mt-2">
      <div class="custom-control custom-radio">
        <div class="form-group mb-0">
          <input type="radio"
            class="custom-control-input"
            name="paymentMethod"
            v-bind:id="provider.name"
            v-bind:value="provider.name"
            v-bind:checked="provider.default"
            v-model="paymentMethod"/>
          <label v-bind:for="provider.name" class="custom-control-label w-100 d-flex justify-content-between">
            {{provider.name}}
            <a v-bind:href="provider.supportLink.url"
              target="_blank"
              class="ml-2 text-primary"
              v-if="provider.supportLink">{{provider.supportLink.text}}</a>
          </label>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
  import { mapGetters, } from "vuex";
  export default {
    props: ["value", "$v"],
    computed: {
      ...mapGetters({
        enabledPaymentProviders: "system/enabledPaymentProviders",
      }),
      paymentMethod: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    },
  };
</script>